* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Montserrat", sans-serif;
}

body {
  margin: 0% 10%;
}

h4 {
  font-size: 1.5rem;
  color: rgb(56, 56, 56);
}

h3 {
  font-size: 1.5rem;
  line-height: 2.5rem;
  margin: 1rem 0;
  color: rgb(56, 56, 56);
}

a {
  color: rgb(56, 56, 56);
  text-decoration: none;
}
